import * as React from 'react';
import { useAtomValue } from 'jotai';
import useAppRouter from '@hooks/useAppRouter';
import { pathAtom } from '@src/stores/path';
import {
  BackButtonWrap,
  CenterTitleWrap,
  HeaderWrap,
  RightButtonContainerWrap,
} from '@components/layout/styles/header.style';
import Text from '@components/common/Text';
import { HeaderColorType } from '@styles/styleUtils';
import { Assets } from '@src/svg';

export interface HeaderProps {
  pageTitle?: string | React.ReactElement;
  titleAlign?: 'left' | 'center';
  handleClickBack?: () => void;
  hideBackButton?: boolean;
  headerRightButton?: JSX.Element;
  headerColor?: HeaderColorType;
  showWebBanner?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  pageTitle,
  titleAlign = 'center',
  handleClickBack,
  hideBackButton,
  headerRightButton,
  headerColor = 'white',
  showWebBanner,
}) => {
  const router = useAppRouter();
  const path = useAtomValue(pathAtom);

  const onClickBackButton = () => {
    if (handleClickBack) {
      handleClickBack();
    } else if (!path.prevPath) {
      router.push('/');
    } else {
      router.back();
    }
  };

  return (
    <HeaderWrap
      id="header"
      $headerColor={headerColor}
      $showWebBanner={showWebBanner}
    >
      <div className="header-content">
        {!hideBackButton && titleAlign !== 'left' && (
          <BackButtonWrap onClick={onClickBackButton} data-testid="back-button">
            <Assets.BackIcon />
          </BackButtonWrap>
        )}
        {titleAlign === 'left' && (
          <Text typo="heading1_semiBold">{pageTitle}</Text>
        )}
        {titleAlign === 'center' && (
          <CenterTitleWrap>{pageTitle}</CenterTitleWrap>
        )}
        {headerRightButton && (
          <RightButtonContainerWrap id="header-right-btn">
            {headerRightButton}
          </RightButtonContainerWrap>
        )}
      </div>
    </HeaderWrap>
  );
};

export default Header;
