import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { MouseEvent, useState } from 'react';
import { themeV2 } from '@styles/theme_v2';
import { getColorValue } from '@styles/styleUtils';

type Mode = keyof typeof themeV2.buttonColorV2;
type Variant = keyof (typeof themeV2.buttonColorV2)[Mode];
type Priority =
  | keyof (typeof themeV2.buttonColorV2)[Mode][Variant]
  | 'secondary';
type Size = keyof typeof themeV2.buttonSizeV2;

export const CtaButtonWrap = styled.button<{
  mode: Mode;
  variant: Variant;
  priority: Priority;
  size: Size;
  disabled?: boolean;
  $active?: boolean;
  $disabledStyle?: boolean;
  $fullWidth?: boolean;
  $isLoading?: boolean;
  $noActive?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  ${({ theme, size }) => theme.buttonSizeV2[size]};
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
  ${({ theme, mode, variant, priority, $active }) =>
    $active
      ? theme.buttonColorV2[mode][variant][priority].pressed
      : theme.buttonColorV2[mode][variant][priority].default};
  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};

  &:hover,
  &:active {
    ${({ theme, mode, variant, priority, $disabledStyle, $noActive }) =>
      !$noActive &&
      !$disabledStyle &&
      theme.buttonColorV2[mode][variant][priority].pressed};
  }

  ${({ theme, mode, variant, priority, disabled, $disabledStyle }) =>
    (disabled || $disabledStyle) && {
      ...theme.buttonColorV2[mode][variant][priority].disabled,
    }};
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      pointer-events: none;
    `};
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.span`
  border: 3px solid ${getColorValue('gray30')};
  border-top: 3px solid ${getColorValue('gray100')};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spin} 2s linear infinite;
`;

const ButtonIcon = styled.i<{
  size: Size;
}>`
  svg {
    width: 100%;
    height: 100%;
  }

  ${({ size }) => {
    switch (size) {
      case 'large':
        return css`
          width: 20px;
          min-width: 20px;
          height: 20px;
        `;
      case 'medium':
      case 'small':
      default:
        return css`
          width: 18px;
          min-width: 18px;
          height: 18px;
        `;
    }
  }};
`;

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  children?: string | React.ReactElement[] | React.ReactElement;
  mode?: Mode;
  variant?: Variant;
  priority?: Priority;
  size?: Size;
  disabled?: boolean;
  active?: boolean;
  disabledStyle?: boolean;
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  hasLoader?: boolean;
  noActive?: boolean;
}

const CtaButton = ({
  children,
  mode = 'light',
  variant = 'solid',
  priority = 'primary',
  size = 'medium',
  active = false,
  disabled = false,
  disabledStyle = false,
  fullWidth = false,
  onClick,
  leftIcon,
  rightIcon,
  hasLoader,
  noActive = false,
  ...rest
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (hasLoader) {
      setIsLoading(true);

      Promise.resolve(onClick?.(event)).finally(() => {
        setIsLoading(false);
      });
    } else {
      onClick?.(event);
    }
  };

  return (
    <CtaButtonWrap
      $noActive={noActive}
      mode={mode}
      variant={variant}
      priority={priority}
      size={size}
      $active={active}
      disabled={disabled}
      $disabledStyle={disabledStyle}
      $fullWidth={fullWidth}
      onClick={onClickHandler}
      $isLoading={isLoading}
      {...rest}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {leftIcon && <ButtonIcon size={size}>{leftIcon}</ButtonIcon>}
          {children}
          {rightIcon && <ButtonIcon size={size}>{rightIcon}</ButtonIcon>}
        </>
      )}
    </CtaButtonWrap>
  );
};

export default CtaButton;
