import { NextPage } from 'next';
import styled from 'styled-components';
import Header from '@components/layout/Header';
import Logo from '@icons/logo/logo-white-h14.svg';
import LimitIcon from '@icons/limit.svg';
import { PageWrap } from '@styles/common-layouts';
import useAppRouter from '@hooks/useAppRouter';
import CtaButton from '@components/common/CtaButton';
import Text from '@components/common/Text';
import Spacing from '@components/common/Spacing';

const ViewWrap = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const NotFoundPage: NextPage = () => {
  const router = useAppRouter();

  return (
    <PageWrap mode="dark">
      <Header headerColor="black" pageTitle={<Logo />} titleAlign="left" />
      <ViewWrap>
        <LimitIcon />
        <Text typo="body1_semiBold">페이지를 찾을 수 없습니다.</Text>
        <CtaButton
          mode="dark"
          variant="solid"
          priority="primary"
          size="large"
          onClick={() => router.replace('/')}
        >
          메인으로 돌아가기
        </CtaButton>
        <Spacing size={80} />
      </ViewWrap>
    </PageWrap>
  );
};

export default NotFoundPage;
